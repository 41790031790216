import * as React from 'react';
import { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import Button from '@rotaryintl/harris-button';
import RelatedLinks from '@rotaryintl/harris-related-links';
import { Link as GatsbyLink } from 'gatsby';

import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';

import { ClubInfo } from './ClubInfo';
import {
  CountryId,
  noClubInfoCountries,
  noDonateWidgetCountries,
  RIBI_COUNTRY_LIST,
} from './constants';
import DonateWidget from './DonateWidget';
import { AlandIslands } from './PaymentInstructionsByCountry/AlandIslands';
import { India } from './PaymentInstructionsByCountry/India';
import { Korea } from './PaymentInstructionsByCountry/Korea';
import { Kosovo } from './PaymentInstructionsByCountry/Kosovo';
import { Philippines } from './PaymentInstructionsByCountry/Philippines';
import { Ribi } from './PaymentInstructionsByCountry/Ribi';
import './styles.css';

import { localizedNavigate } from '@utils/localized-navigate';

import { getClubFinancePath, getClubIdFromPath } from '@domui-use-cases/clubs';
import { OneColumn } from 'src/domui/components/Layouts/OneColumn';
import NotFound from 'src/domui/components/Routing/NotFound';
import { ClubType, DynamicData } from 'src/domui/domain/type';
import { useAppConfig } from 'src/domui/hooks/appConfig';
import { useFetchAdminAccessRights } from 'src/domui/hooks/iam/useFetchAdminAccessRights';
import { useDominoUserAccount } from 'src/domui/hooks/useDominoUserAccount';
import { getClubTypeName } from 'src/domui/utils/getClubTypeName';
import { ClubTypeEnum } from 'src/domui/utils/isRotaractClub';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-8"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

interface Props extends RouteComponentProps {
  clubId: string;
}

const ClubInvoice: React.FC<Props> = ({ clubId }) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const individualIdForFetch = (user?.isLoggedIn && user.individualId) || null;
  const { data: userData } = useDominoUserAccount(individualIdForFetch || '');

  const { data, loading, fetchAdminAccessRights } = useFetchAdminAccessRights();

  useEffect(() => {
    if (clubId) {
      fetchAdminAccessRights(clubId, userData, user?.individualId ?? undefined);
    }
  }, [clubId, userData, user]);
  const filterClubs = (clubs: DynamicData, clubId: String) => {
    const today = new Date();
    return clubs?.filter(
      (club: {
        id: String;
        startDate: string | number | Date;
        endDate: string | number | Date | null;
      }) => {
        const startDate = club.startDate ? new Date(club.startDate) : null;
        const endDate = club.endDate ? new Date(club.endDate) : null;
        return (
          club.id === clubId &&
          startDate &&
          startDate <= today &&
          (endDate === null || today <= endDate)
        );
      }
    );
  };

  const filteredClubs = filterClubs(
    userData?.getClubDistrictData?.clubs,
    clubId
  );

  const { operationsAccess } = data || {};

  if (data !== null) {
    operationsAccess.financesGoalsReports = data;
  }
  if (loading || !data) {
    return <Loading />;
  }
  if (!operationsAccess?.clubId) {
    return <NotFound default />;
  }

  const hasClubFinanceAccess = true; // done for now as operationsAccess.financeOperationAccess?.hasAccess
  //   operationsAccess.financeOperationAccess?.hasAccess;
  const { type, name, riClubId } = filteredClubs[0];
  const formattedType = type as ClubType;
  const formattedClubType = getClubTypeName(t, formattedType);

  const clubCountry =
    userData?.individualInfo?.addresses?.find(
      (address: { isPrimary: boolean }) => address.isPrimary
    )?.countryId || '';

  const isRibiCountry =
    RIBI_COUNTRY_LIST.includes(clubCountry) && type === ClubTypeEnum.RotaryClub;
  const showDonateWidget = !(
    isRibiCountry || noDonateWidgetCountries.includes(clubCountry)
  );
  const showClubInfo = !(
    isRibiCountry || noClubInfoCountries.includes(clubCountry)
  );

  const storedActiveTabData = localStorage.getItem('activeTabValue');
  const activeTabData = storedActiveTabData
    ? JSON.parse(storedActiveTabData)
    : [];
  const navigatedFromActiveClubTabs = activeTabData[0]?.value;

  const handleBackNavigation = () => {
    let redirectToClubFinancePath = '/my-clubs';
    if (navigatedFromActiveClubTabs) {
      const homeClubId = getClubIdFromPath(navigatedFromActiveClubTabs);
      redirectToClubFinancePath = getClubFinancePath(homeClubId);
    }
    localizedNavigate(redirectToClubFinancePath);
  };

  const links = [
    {
      children: `${t(
        'club-invoice.related-link.current-invoice',
        'Current Invoice'
      )}`,
      to: `/en/domui/club-invoice-report/${clubId}?docType=I&isCurrentInvoice=true`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
    {
      children: `${t(
        'club-invoice.related-link.current-invoice-details',
        'Current Invoice Details'
      )}`,
      to: `/en/domui/club-invoice-report/${clubId}?docType=D&isCurrentInvoice=true`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
    {
      children: `${t(
        'club-invoice.related-link.previous-invoice',
        'Previous Invoice'
      )}`,
      to: `/en/domui/club-invoice-report/${clubId}?docType=I&isCurrentInvoice=false`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
    {
      children: `${t(
        'club-invoice.related-link.previous-invoice-details',
        'Previous Invoice Details'
      )}`,
      to: `/en/domui/club-invoice-report/${clubId}?docType=D&isCurrentInvoice=false`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
  ];

  const getPaymentInstructionsByCountry = (country: string) => {
    switch (country) {
      case CountryId.RIBI:
        return <Ribi />;
      case CountryId.IND:
        return <India />;
      case CountryId.KOR:
        return <Korea />;
      case CountryId.PHL:
        return <Philippines />;
      case CountryId.XKX:
        return <Kosovo />;
      case CountryId.ALA:
        return <AlandIslands />;
      default:
        return null;
    }
  };

  if (!hasClubFinanceAccess) {
    return (
      <OneColumn className="mb-20">
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <OneColumn>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.club-invoice',
          'Club Invoice'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <BackButton
        t={t}
        onBackClick={() => localizedNavigate(getClubFinancePath(clubId))}
      />
      <h1 className="font-semibold text-xl-38 leading-lg-45 mobile-m:text-xl-48 mobile-m:leading-xl-57 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-70">
        {t('club-invoice.title', 'Club Invoice')}
      </h1>
      <div className="flex flex-col tablet:flex-row tablet:justify-between gap-8 tablet:gap-28 wrap mb-12 tablet:mb-20">
        <div className="w-full tablet:w-2/3">
          {showClubInfo && (
            <ClubInfo
              formattedClubType={formattedClubType}
              clubName={name}
              clubType={type}
            />
          )}

          {(isRibiCountry || noDonateWidgetCountries.includes(clubCountry)) &&
            getPaymentInstructionsByCountry(
              isRibiCountry ? CountryId.RIBI : clubCountry
            )}
        </div>

        {showClubInfo && (
          <div className="w-full tablet:w-1/3">
            <RelatedLinks
              title={t(
                'club-invoice.related-link.title',
                'Club Invoice and Details'
              )}
              description={t(
                'club-invoice.related-link.description',
                'View current and previous club invoices and details.'
              )}
              links={links}
            />
          </div>
        )}
      </div>

      {showDonateWidget && (
        <DonateWidget
          riClubId={riClubId}
          clubType={
            type
            // .replaceAll('_', ' ')
          }
        />
      )}
    </OneColumn>
  );
};

export default ClubInvoice;
