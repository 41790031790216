import React from 'react';

import Alert from '@rotaryintl/harris-alert';
import moment from 'moment';

import { ClubType } from 'src/domui/domain/type';

import { useTranslation } from '@external/react-i18next';

export const ClubInfo = ({
  clubName,
  formattedClubType,
  clubType,
}: {
  clubName: string;
  formattedClubType: string;
  clubType: string;
}) => {
  const { t } = useTranslation();
  const currentDate = moment().format('DD MMMM YYYY');
  return (
    <>
      <h4 className="mb-6 py-0 font-semibold text-lg tablet:text-lg-24 leading-md-30 tablet:leading-lg-36">
        {t('club-invoice.club-name', '{{formattedClubType}} of {{clubName}}', {
          formattedClubType,
          clubName,
        })}
      </h4>
      <h5 className="my-4 py-0 text-2md font-semibold leading-lg-heading">
        {t('club-invoice.balance-date', 'Balance as of {{currentDate}}', {
          currentDate,
        })}
      </h5>
      <p className="my-4 py-0 text-md leading-md">
        {clubType === ClubType.RotaryClub
          ? `${t(
              'club-invoice.payment-due-info-rotaract-club',
              'Payment is due for the following balance. Dues are calculated based on RI membership information as of 1 January. Payment is due upon receipt.'
            )}`
          : `${t(
              'club-invoice.payment-due-info-rotary-club',
              'Payment is due for the following balance. Dues are calculated based on RI membership information as of 1 January and 1 July. Payment is due upon receipt.'
            )}`}
      </p>
      <p className="my-4 py-0 text-md leading-md">
        {t(
          'club-invoice.help',
          'If you have questions about your account, please contact your <a href="https://my.rotary.org/en/contact/representatives">Financial Representative.</a>'
        )}
      </p>
      <Alert variant="info" isDismissible={false}>
        {t(
          'club-invoice.note',
          'Note: Processing of invoice payments may take some time. If you have already made a payment but do not see it reflected, please check back later.'
        )}
      </Alert>
    </>
  );
};
