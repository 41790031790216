import * as React from 'react';

import { Router } from '@reach/router';

import NotFound from '@components/Routing/NotFound';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';

import RequireLogin from '@domui-components/Auth/RequireLogin';
import ClubInvoice from '@domui-presenters/web/pages/ClubInvoice';

const ClubInvoicePage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }
  return (
    <RequireLogin>
      <Router basepath={`${languagePrefix}`}>
        <ClubInvoice
          path="/domui/club-invoice/:clubId"
          clubId="is set from path"
        />
      </Router>
    </RequireLogin>
  );
};

export default ClubInvoicePage;
