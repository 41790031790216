import React from 'react';

import Loading from '@components/Loading';

// import { useUserAccount } from '@repositories/auth/hooks';

import { waitForDonateWidget } from '@utils/waitForDonateWidget';

import { useAppConfig } from '@domui-hooks/appConfig';
import { useDominoUserAccount } from '@domui-hooks/useDominoUserAccount';

interface Props {
  riClubId: string;
  clubType: string;
}

const DonateWidget: React.FC<Props> = ({ riClubId, clubType }) => {
  const { user } = useAppConfig();
  const userIndividualId = (user?.isLoggedIn && user.individualId) || null;
  const { data: userData } = useDominoUserAccount(userIndividualId || '');

  if (!userData) {
    return <Loading />;
  }

  if (userData && user) {
    const { firstName, lastName, localizedName } = userData.individualInfo;

    const email = userData.individualInfo.emails.find(
      (email: { isPrimary: boolean }) => email.isPrimary
    );

    const primaryAddress = userData.individualInfo.addresses.find(
      (address: { isPrimary: boolean }) => address.isPrimary
    );
    const primaryPhone = userData.individualInfo.phones.find(
      (phones: { isPrimary: boolean }) => phones.isPrimary
    );

    let serverValue = JSON.stringify({
      user: {
        authenticated: user.isLoggedIn,
        member_id: user.riIndividualId,
        first_name: firstName,
        last_name: lastName,
        localized_name: localizedName || '',
        preferred_language: window.__gatsby_language,
        phone: primaryPhone?.number,
        email: email?.address,
        address: {
          line1: primaryAddress?.lineOne || '',
          line2: primaryAddress?.lineTwo || '',
          line3: primaryAddress?.lineThree || '',
          city: primaryAddress?.city || '',
          state: primaryAddress?.stateName || '',
          zip: primaryAddress?.postalCode || '',
          intl_province: primaryAddress?.internationalProvince || '',
          country: primaryAddress?.countryName || '',
        },
        individual: {},
        organization: {
          id: riClubId,
          type: clubType,
        },
        created_by: email?.address,
      },
    });

    serverValue = serverValue.replace("'", '\x27');
    serverValue = JSON.parse(serverValue);
    window.localStorage.setItem('serverValue', JSON.stringify(serverValue));
    if (!document.getElementById('donate-widget')) {
      waitForDonateWidget('#wrapper-id', 500, 5000);
    }
  }

  return (
    <div id="donate-widget" className="mb-6 mobile-l:mb-12 tablet:mb-20" />
  );
};

export default DonateWidget;
